import { fields } from "@monorepo/utils/src/variables/projectsData/tkGeneration/fields";

export const filtersElements = [
  {
    value: fields.OIK,
    title: "ОИК",
  },
  {
    value: fields.FILE_TYPE,
    title: "Тип файла",
  },
  {
    value: fields.SIGNING,
    title: "Подписание ТК (Вкл/Выкл)",
  },
  {
    value: fields.MAIN_FILE,
    title: "Основной файл",
  },
  {
    value: fields.ADDITIONAL_FILES,
    title: "Дополнительные файлы",
  },
  {
    value: fields.IS_CUSTOM_FILE,
    title: "Загрузить свои файлы",
  },
  {
    value: fields.ADD_CASE,
    tooltip: "Будет сгенерировано две описи, опись эад (два дела: эад, гибридное) и опись бумажная (два дела: гибридное и бумажное).",
    title: "Добавить бумажную опись и гибридное дело",
  },
  {
    value: fields.ADD_PAPER_CASE,
    tooltip: "Будет сгенерирована бумажная опись с бумажными делами.",
    title: "Добавить бумажную опись и бумажное дело",
  },
  {
    value: fields.ADD_MRD,
    title: "Добавить МЧД",
  },
  {
    value: fields.PREFACE_REQUIRED,
    title: "Добавить файл Предисловие",
  },
  {
    value: fields.IS_MRD_EXPIRED,
    title: "Истекший срок МЧД",
  },
  {
    value: fields.IS_HYBRID_SAME_GUID,
    title: "Дублировать номер гибридного дела, как у бумажного/электронного",
  },
  {
    value: fields.TRUSTED_CERT,
    title: "Доверитель",
  },
  {
    value: fields.REPRESENTATIVE_CERT,
    title: "Представитель",
  },
  {
    value: fields.MIN_SIZE,
    title: "Минимальный размер, Мб",
  },
  {
    value: fields.MAX_SIZE,
    title: "Максимальный размер, Мб",
  },
  {
    value: fields.APPLICATIONS_NUMBER,
    title: "Количество приложений",
  },
  {
    value: fields.INVENTORY_NUMBER,
    title: "Количество описей дел",
  },
  {
    value: fields.EAD_NUMBER,
    title: "Количество ЭАД",
  },
  {
    value: fields.INVENTORY_CATEGORY,
    title: "Тип описи",
  },
  {
    value: fields.EAD_NUMBER,
    title: "Количество ЭАД",
  },
  {
    value: fields.FILTERS_ERC_PROTOCOLS_ID,
    title: "Индентификатор",
  },
  {
    value: fields.FILTERS_ERC_PROTOCOLS_VERSION,
    title: "Версия",
  },
  {
    value: fields.FILTERS_ERC_ADD_CASE,
    tooltip: "Будет сгенерировано две описи, опись эад (два дела: эад, гибридное) и опись бумажная (два дела: гибридное и бумажное).",
    title: "Добавить бумажную опись и гибридное дело",
  },
  {
    value: fields.FILTERS_ERC_ADD_PAPER_CASE,
    tooltip: "Будет сгенерирована бумажная опись с бумажными делами.",
    title: "Добавить бумажную опись и бумажное дело",
  },
  {
    value: fields.FILTERS_ERC_WITH_HISTORICAL_NOTE,
    title: "Добавить историческую справку",
  },
  {
    value: fields.OIK_GENERATE_TEXT,
    title: "Текст сообщения",
  },
  {
    value: fields.OIK_GENERATE_CODE_RESPONSE,
    title: "Код ответа",
  },
  {
    value: fields.OIK_GENERATE_TK,
    title: "ТК на который надо сгенерировать ответ",
  },
  {
    value: fields.INVENTORY_FILTER,
    title: "Проекты описи дел",
  },

  {
    value: fields.FILTERS_NOMENCLATURE_YEAR,
    title: "Год",
  },
  {
    value: fields.FILTERS_NOMENCLATURE_SECTION,
    title: "Кол-во секций",
  },
  {
    value: fields.FILTERS_NOMENCLATURE_CASE,
    title: "Кол-во дел",
  },
  {
    value: fields.FILTERS_NOMENCLATURE_SUBSECTIONS,
    title: "Добавить вложенные секции",
  },
  {
    value: fields.FILTERS_NSI_TK,
    title: "ТК",
  },
  {
    value: fields.FILTERS_NSI_STATUS,
    title: "Статус",
  },
];
