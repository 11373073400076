import { Module } from "vuex";
import { IRootState } from "@monorepo/informationSecurity/src/store";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { checkExistLibrary, getFullPath, getQuery } from "@monorepo/utils/src/api/utils";
import { IFilestorage } from "@monorepo/informationSecurity/src/views/Filestorage/types/filestorage";
import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { mutations as baseMutations } from "@monorepo/utils/src/store/modules/mutations";
import { actions as baseActions } from "@monorepo/utils/src/store/modules/actions";
import { getters as baseGetters } from "@monorepo/utils/src/store/modules/getters";
import { ISection, Sections } from "@monorepo/utils/src/types/cellsSections";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";
import axios, { AxiosResponse } from "axios";
import { IResponse } from "@monorepo/utils/src/api/types/response";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { fields } from "@monorepo/utils/src/variables/projectsData/tkGeneration/fields";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import { convertStatusToApi } from "@monorepo/administration/src/views/TkGeneration/utils/convertStatusToApi";

interface ITableState {
  filters: ITableFiltersObject;
  totalLength: number;
  data: IFilestorage[];
  infiniteId: string;
  sectionCells: ISection;
  section?: Sections;
  isOpenFilters: boolean;
  isLoadedInventories: boolean;
  cells: string[];
  searchTemplates: ISearchTemplate[];
  libraries: {
    oiks: { code: string; name: string }[];
    fileTypes: string[];
    responseCodes: string[];
    inventories: Record<string, unknown>[];
    tkList: Record<string, unknown>[];
    certificates: Record<string, unknown>[];
    oikCodes: { tkGuid: string; oikCode: string; oikShortName: string; transferDate: string }[];
    inventoryCategories: any[];
  };
  isTableLoading: boolean;
  isHasGeneratedTk: boolean;
}

const defaultFilters = (): ITableFiltersObject => ({
  sort: {},
  openedId: null,
  isSelectAll: false,
  selectedIds: {},
  fieldFilters: {},
  initMessagesLength: 50,
});

const getDefaultState = (): ITableState => ({
  filters: defaultFilters(),
  infiniteId: new Date().toString(),
  totalLength: 0,
  data: [],
  cells: [],
  searchTemplates: [],
  section: Sections.TK_GENERATION,
  sectionCells: {} as ISection,
  isOpenFilters: false,
  isLoadedInventories: false,
  libraries: {
    oiks: [],
    fileTypes: [],
    inventories: [],
    responseCodes: [],
    oikCodes: [],
    tkList: [],
    certificates: [],
    inventoryCategories: [
      {
        title: "Постоянно",
        value: "FOREVER",
      },
      {
        title: "По личному составу",
        value: "BY_PERSONNEL",
      },
    ],
  },
  isTableLoading: false,
  isHasGeneratedTk: false,
});

export const module: Module<ITableState, IRootState> = {
  namespaced: true,
  state: getDefaultState,
  mutations: {
    ...baseMutations,
    setFilters(state: ITableState, payload: ReturnType<typeof defaultFilters>) {
      state.filters = payload;
    },
    resetState(state: ITableState) {
      Object.assign(state, getDefaultState());
    },
    clearFilters(state: ITableState) {
      state.filters = defaultFilters();
    },
    addFileTypes(state: ITableState, payload: string[]) {
      state.libraries.fileTypes = payload?.filter((item: string) => item !== "ODT") ?? [];
    },
    addOiks(state: ITableState, payload: { code: string; name: string }[]) {
      state.libraries.oiks = payload;
    },
    addTkList(state: ITableState, payload: Record<string, unknown>[]) {
      state.libraries.tkList = payload;
    },
    addResponseCodes(state: ITableState, payload: string[]) {
      state.libraries.responseCodes = payload;
    },
    isLoadedInventories(state: ITableState, payload: boolean) {
      state.isLoadedInventories = payload;
    },
    addOikCodes(state: ITableState, payload: { tkGuid: string; oikCode: string; oikShortName: string; transferDate: string }[]) {
      state.libraries.oikCodes = payload;
    },
    addInventories(state: ITableState, payload: Record<string, unknown>[]) {
      state.libraries.inventories = payload;
    },
    addCertificates(state: ITableState, payload: Record<string, unknown>[]) {
      state.libraries.certificates = payload;
    },
    setTableLoading(state: ITableState, payload: boolean) {
      state.isTableLoading = payload;
    },
    addIsHasGeneratedTk(state: ITableState, payload: boolean) {
      state.isHasGeneratedTk = payload;
    },
  },
  actions: {
    ...baseActions,
    async getResponseCodes({ commit, state }) {
      try {
        checkExistLibrary(state.libraries.fileTypes, async () => {
          const { data }: AxiosResponse<IResponse<string[]>> = await axios.get(getFullPath(QUERY_PATH.GET_DIC_RESPONSE_CODES));
          commit("addResponseCodes", data);
        });
      } catch (e) {
        showNotification("Ошибка получения списка кодов ответа.");
      }
    },
    async getOikCodes({ commit, state }) {
      try {
        checkExistLibrary(state.libraries.fileTypes, async () => {
          const { data }: AxiosResponse<IResponse<{ tkGuid: string; oikCode: string; oikShortName: string; transferDate: string }[]>> =
            await axios.get(getFullPath(QUERY_PATH.GET_OIK_RESPONSE));
          commit("addOikCodes", data.data);
        });
      } catch (e) {
        showNotification("Ошибка получения списка ОИК.");
      }
    },

    async getFileTypes({ commit, state }) {
      try {
        checkExistLibrary(state.libraries.fileTypes, async () => {
          const { data }: AxiosResponse<IResponse<string[]>> = await axios.get(getFullPath(QUERY_PATH.GET_FILE_TYPES_LIB));
          commit("addFileTypes", data);
        });
      } catch (e) {
        showNotification("Ошибка получения списка типов файла.");
      }
    },
    async moveGeneratedTk(info, containerType: string): Promise<boolean> {
      try {
        const { data } = await axios.post(getFullPath(QUERY_PATH.MOVE_GENERATED_TK), { containerType, actionType: "MOVE" });
        return data;
      } catch (e) {
        showNotification("Ошибка перемещения ТК.");
        return false;
      }
    },
    async clearGeneratedTkFolder(): Promise<boolean> {
      try {
        const { data } = await axios.post(getFullPath(QUERY_PATH.CLEAR_GENERATED_TK_FOLDER));
        return data;
      } catch (e) {
        showNotification("Ошибка удаления информации по ТК.");
        return false;
      }
    },
    async generateNomenclature({ state, dispatch }) {
      try {
        const { data } = await axios.post(getFullPath(QUERY_PATH.GENERATE_NOMENCLATURE), {
          oikCode: state.filters.fieldFilters[fields.FILTERS_NOMENCLATURE_OIK],
          year: state.filters.fieldFilters[fields.FILTERS_NOMENCLATURE_YEAR],
          sectionCount: state.filters.fieldFilters[fields.FILTERS_NOMENCLATURE_SECTION],
          caseCount: state.filters.fieldFilters[fields.FILTERS_NOMENCLATURE_CASE],
          signing: state.filters.fieldFilters[fields.FILTERS_NOMENCLATURE_SIGNING],
          subsection: state.filters.fieldFilters[fields.FILTERS_NOMENCLATURE_SUBSECTIONS],
        });
        dispatch("getHasGeneratedTk");
        showNotification("Cоздание номенклатуры успешно запущено", NOTIFICATION_STATUS.SUCCESS);
        return data;
      } catch (e) {
        showNotification("Ошибка создания номенклатуры.");
        return false;
      }
    },
    async startTransferEndJob(): Promise<boolean> {
      try {
        const { data } = await axios.post(getFullPath(QUERY_PATH.GET_AUTOMATE_OIK_TRANSFER_END_JOB));
        return data;
      } catch (e) {
        showNotification("Ошибка отправки уведомления об истечении срока передачи дел.");
        return false;
      }
    },
    async startTransferHistoryJob(root, params = {}): Promise<boolean> {
      try {
        const { data } = await axios.post(getFullPath(QUERY_PATH.GET_AUTOMATE_TRANSFER_HISTORY_JOB), {}, { params });
        return data;
      } catch (e) {
        showNotification("Ошибка переноса графика дел на следующий год.");
        return false;
      }
    },
    async startOikUpdateJob(): Promise<boolean> {
      try {
        const { data } = await axios.post(getFullPath(QUERY_PATH.GET_AUTOMATE_START_OIK_UPDATE_JOB));
        return data;
      } catch (e) {
        showNotification("Ошибка автоматического обновления справочников.");
        return false;
      }
    },
    async startIndexingJob(): Promise<boolean> {
      try {
        const { data } = await axios.post(getFullPath(QUERY_PATH.GET_START_INDEXING));
        return data;
      } catch (e) {
        showNotification("Ошибка запуска индексации сущностей системы.");
        return false;
      }
    },
    async generateNsi({ state }): Promise<void> {
      try {
        await axios.post(
          getFullPath(QUERY_PATH.GET_GENERATE_TK_NSI),
          {},
          {
            params: {
              tkGuid: state.filters.fieldFilters[fields.FILTERS_NSI_TK],
              [fields.FILTERS_NSI_STATUS]: convertStatusToApi(state.filters.fieldFilters[fields.FILTERS_NSI_STATUS] as string),
            },
          }
        );
        showNotification("ТК с ответом от ГАС НСИ успешно сгенерирован", NOTIFICATION_STATUS.SUCCESS);
      } catch (e) {
        showNotification("Ошибка генерации ТК с ответом от ГАС НСИ.");
      }
    },
    async getHasGeneratedTk({ commit }): Promise<boolean> {
      try {
        const { data } = await axios.get(getFullPath(QUERY_PATH.GET_HAS_GENERATED_TK));
        commit("addIsHasGeneratedTk", data.data);
        return data.data;
      } catch (e) {
        return false;
      }
    },
    async getInventories({ commit }) {
      commit("isLoadedInventories", true);
      const params = {
        filters: [{ key: "status", value: ["Утверждено решение принять проект описи дел", "Согласовано с замечаниями"] }],
        sort: [],
        offset: 0,
        limit: 100000,
      };
      const { data } = await getQuery<Record<string, unknown>[]>(
        QUERY_PATH.GET_EXPERT_COMMISSION_LIST,
        params,
        true,
        "Ошибка получения списка проектов описей дел"
      ).finally(() => {
        commit("isLoadedInventories", false);
      });
      commit("addInventories", data || []);
    },
    async getOiks({ commit, state }) {
      checkExistLibrary(state.libraries.oiks, async () => {
        const { data } = await getQuery<{ code: string; name: string }[]>(
          QUERY_PATH.GET_OIKS_SHORT_NAMES_LIB,
          {},
          true,
          "Ошибка получения списка ОИК"
        );
        commit("addOiks", data);
      });
    },
    async getCertificates({ commit }) {
      try {
        const { data } = await axios.get<Record<string, never>>(QUERY_PATH.GET_VIPNET_CERTS_ALL);
        commit("addCertificates", data.data || []);
      } catch (e) {
        showNotification("Ошибка загрузки списка сертификатов");
      }
    },
    async getTkList({ commit, state }) {
      checkExistLibrary(state.libraries.tkList, async () => {
        const { data } = await getQuery<Record<string, unknown>[]>(QUERY_PATH.GET_NSI_TK_LIB, {}, true, "Ошибка получения списка ТК");
        commit("addTkList", data);
      });
    },
    async generateTk({ state }) {
      try {
        const formData = new FormData();
        formData.append("mainFile", state.filters.fieldFilters[fields.IS_CUSTOM_FILE] ? (state.filters.fieldFilters[fields.MAIN_FILE] as any) : "");
        if (state.filters.fieldFilters[fields.IS_CUSTOM_FILE] && (state.filters.fieldFilters[fields.ADDITIONAL_FILES] as any[]).length) {
          (state.filters.fieldFilters[fields.ADDITIONAL_FILES] as any[]).forEach((file) => {
            formData.append("files", file);
          });
        } else {
          formData.append("files", "");
        }

        formData.append(
          "params",
          JSON.stringify({
            [fields.OIK]: state.filters.fieldFilters[fields.OIK],
            [fields.SIGNING]: state.filters.fieldFilters[fields.SIGNING],
            [fields.ADD_MRD]: state.filters.fieldFilters[fields.ADD_MRD],
            [fields.ADD_CASE]: state.filters.fieldFilters[fields.ADD_CASE],
            [fields.ADD_PAPER_CASE]: state.filters.fieldFilters[fields.ADD_PAPER_CASE],
            [fields.IS_HYBRID_SAME_GUID]: state.filters.fieldFilters[fields.IS_HYBRID_SAME_GUID],
            ...(state.filters.fieldFilters[fields.IS_CUSTOM_FILE]
              ? {}
              : {
                  [fields.MIN_SIZE]: !state.filters.fieldFilters[fields.IS_CUSTOM_FILE] ? +state.filters.fieldFilters[fields.MIN_SIZE] || 0 : "",
                  [fields.MAX_SIZE]: !state.filters.fieldFilters[fields.IS_CUSTOM_FILE] ? +state.filters.fieldFilters[fields.MAX_SIZE] || 0 : "",
                  [fields.APPLICATIONS_NUMBER]: !state.filters.fieldFilters[fields.IS_CUSTOM_FILE]
                    ? +state.filters.fieldFilters[fields.APPLICATIONS_NUMBER] || 0
                    : "",
                }),
            [fields.FILE_TYPE]: state.filters.fieldFilters[fields.FILE_TYPE] || null,
            [fields.EAD_NUMBER]: +state.filters.fieldFilters[fields.EAD_NUMBER] || 0,
            [fields.INVENTORY_NUMBER]: +state.filters.fieldFilters[fields.INVENTORY_NUMBER] || 0,
            ...(state.filters.fieldFilters[fields.ADD_MRD]
              ? {
                  [fields.IS_MRD_EXPIRED]: state.filters.fieldFilters[fields.IS_MRD_EXPIRED],
                  [fields.TRUSTED_CERT]: state.filters.fieldFilters[fields.TRUSTED_CERT],
                  [fields.REPRESENTATIVE_CERT]: state.filters.fieldFilters[fields.REPRESENTATIVE_CERT],
                }
              : {}),
            [fields.PREFACE_REQUIRED]: state.filters.fieldFilters[fields.PREFACE_REQUIRED],
            [fields.INVENTORY_CATEGORY]: state.filters.fieldFilters[fields.INVENTORY_CATEGORY],
          })
        );
        await axios.post(getFullPath(QUERY_PATH.GET_TK_GENERATE), formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        return true;
      } catch (e) {
        showNotification("Ошибка генерации ТК.");
        return false;
      }
    },
    async generateERCProtocolsTk({ state }) {
      try {
        await axios.post(getFullPath(QUERY_PATH.GET_TK_ERC_PROTOCOLS_GENERATE), {
          [fields.OIK]: state.filters.fieldFilters[fields.FILTERS_ERC_PROTOCOLS_OIK],
          [fields.SIGNING]: state.filters.fieldFilters[fields.FILTERS_ERC_SIGNING],
          [fields.MIN_SIZE]: +state.filters.fieldFilters[fields.FILTERS_ERC_PROTOCOLS_MIN_SIZE] || 0,
          [fields.MAX_SIZE]: +state.filters.fieldFilters[fields.FILTERS_ERC_PROTOCOLS_MAX_SIZE] || 0,
          [fields.APPLICATIONS_NUMBER]: +state.filters.fieldFilters[fields.FILTERS_ERC_PROTOCOLS_APPLICATIONS_NUMBER] || 0,
          [fields.EAD_NUMBER]: +state.filters.fieldFilters[fields.FILTERS_ERC_PROTOCOLS_EAD_NUMBER] || 0,
          [fields.INVENTORY_NUMBER]: +state.filters.fieldFilters[fields.FILTERS_ERC_PROTOCOLS_INVENTORY_NUMBER] || 0,
          [fields.ID]: state.filters.fieldFilters[fields.FILTERS_ERC_PROTOCOLS_ID],
          [fields.VERSION]: +state.filters.fieldFilters[fields.FILTERS_ERC_PROTOCOLS_VERSION] || 1,
          addHybridInventoryAndPaperCase: !!state.filters.fieldFilters[fields.FILTERS_ERC_ADD_CASE],
          addPaperCase: !!state.filters.fieldFilters[fields.FILTERS_ERC_ADD_PAPER_CASE],
          withHistoricalNote: !!state.filters.fieldFilters[fields.FILTERS_ERC_WITH_HISTORICAL_NOTE],
          [fields.FILE_TYPE]: state.filters.fieldFilters[fields.FILTERS_ERC_PROTOCOLS_FILE_TYPE] || null,
        });
        return true;
      } catch (e) {
        showNotification("Ошибка генерации ТК проекта описи дел.");
        return false;
      }
    },
    async generateComissionTk({ state }) {
      try {
        await axios.post(getFullPath(QUERY_PATH.GET_TK_COMISSION_GENERATE), {
          [fields.INVENTORY_FILTER]: state.filters.fieldFilters[fields.INVENTORY_FILTER],
        });
        return true;
      } catch (e) {
        showNotification("Ошибка генерации ответ на утвержденный проект описи дел.");
        return false;
      }
    },
    async generateOikTk({ state }) {
      try {
        await axios.post(getFullPath(QUERY_PATH.GET_TK_OIK_GENERATE), {
          [fields.OIK]: state.libraries.oikCodes.find((item) => item.tkGuid === state.filters.fieldFilters[fields.OIK_GENERATE_TK])?.oikCode,
          [fields.OIK_GENERATE_TK]: state.filters.fieldFilters[fields.OIK_GENERATE_TK],
          [fields.OIK_GENERATE_CODE_RESPONSE]: state.filters.fieldFilters[fields.OIK_GENERATE_CODE_RESPONSE],
          [fields.OIK_GENERATE_TEXT]: state.filters.fieldFilters[fields.OIK_GENERATE_TEXT],
        });
        return true;
      } catch (e) {
        showNotification("Ошибка генерации и перемещения в папку для входящих МЭДО.");
        return false;
      }
    },
    async deleteTKInformation(): Promise<boolean> {
      try {
        await axios.delete(getFullPath(QUERY_PATH.DELETE_TK_INFORMATION));
        showNotification("Информация успешно удалена.", NOTIFICATION_STATUS.SUCCESS);
        return true;
      } catch (e) {
        showNotification("Ошибка удаления.");
        return false;
      }
    },
    async deleteAllItems(): Promise<boolean> {
      try {
        await axios.delete(getFullPath(QUERY_PATH.DELETE_ALL_ITEMS));
        showNotification("Сущности успешно удалены.", NOTIFICATION_STATUS.SUCCESS);
        return true;
      } catch (e) {
        showNotification("Ошибка удаления.");
        return false;
      }
    },
    async deleteAllDictionaries(): Promise<boolean> {
      try {
        await axios.delete(getFullPath(QUERY_PATH.DELETE_ALL_DICTIONARIES));
        showNotification("Справочники успешно удалены.", NOTIFICATION_STATUS.SUCCESS);
        return true;
      } catch (e) {
        showNotification("Ошибка удаления.");
        return false;
      }
    },
    async addDefaultItems(): Promise<boolean> {
      try {
        await axios.post(getFullPath(QUERY_PATH.ADD_DEFAULT_ITEMS));
        showNotification("Справочники по умолчанию добавлены.", NOTIFICATION_STATUS.SUCCESS);
        return true;
      } catch (e) {
        showNotification("Ошибка добавления.");
        return false;
      }
    },
  },
  getters: {
    ...baseGetters,
    fileTypes(state: ITableState) {
      return state.libraries.fileTypes;
    },
    oiks(state: ITableState) {
      return state.libraries.oiks;
    },
    tkList(state: ITableState) {
      return state.libraries.tkList;
    },
    oikCodes(state: ITableState) {
      return state.libraries.oikCodes;
    },
    inventories(state: ITableState) {
      return state.libraries.inventories;
    },
    responseCodes(state: ITableState) {
      return state.libraries.responseCodes;
    },
    isTableLoading(state: ITableState) {
      return state.isTableLoading;
    },
    isLoadedInventories(state: ITableState) {
      return state.isLoadedInventories;
    },
    isHasGeneratedTk(state: ITableState) {
      return state.isHasGeneratedTk;
    },
    certificates(state: ITableState) {
      return state.libraries.certificates;
    },
    inventoryCategories(state: ITableState) {
      return state.libraries.inventoryCategories;
    },
  },
};
