export interface ITkGenerationFields {
  OIK: string;
  FILE_TYPE: string;
  SIGNING: string;
  ADD_MRD: string;
  IS_MRD_EXPIRED: string;
  IS_HYBRID_SAME_GUID: string;
  ADD_CASE: string;
  ADD_PAPER_CASE: string;
  WITH_HISTORICAL_NOTE: string;
  IS_CUSTOM_FILE: string;
  MIN_SIZE: string;
  OIK_GENERATE_TK: string;
  OIK_GENERATE_CODE_RESPONSE: string;
  INVENTORY_FILTER: string;
  OIK_GENERATE_TEXT: string;
  MAX_SIZE: string;
  INVENTORY_NUMBER: string;
  EAD_NUMBER: string;
  APPLICATIONS_NUMBER: string;
  ID: string;
  VERSION: string;
  MAIN_FILE: string;
  ADDITIONAL_FILES: string;
  TRUSTED_CERT: string;
  REPRESENTATIVE_CERT: string;
  PREFACE_REQUIRED: string;
  INVENTORY_CATEGORY: string;

  FILTERS_ERC_PROTOCOLS_OIK: string;
  FILTERS_ERC_PROTOCOLS_FILE_TYPE: string;
  FILTERS_ERC_SIGNING: string;
  FILTERS_ERC_PROTOCOLS_MIN_SIZE: string;
  FILTERS_ERC_PROTOCOLS_MAX_SIZE: string;
  FILTERS_ERC_PROTOCOLS_ID: string;
  FILTERS_ERC_PROTOCOLS_VERSION: string;
  FILTERS_ERC_PROTOCOLS_APPLICATIONS_NUMBER: string;
  FILTERS_ERC_PROTOCOLS_INVENTORY_NUMBER: string;
  FILTERS_ERC_PROTOCOLS_EAD_NUMBER: string;
  FILTERS_ERC_ADD_CASE: string;
  FILTERS_ERC_ADD_PAPER_CASE: string;
  FILTERS_ERC_WITH_HISTORICAL_NOTE: string;

  FILTERS_NOMENCLATURE_OIK: string;
  FILTERS_NOMENCLATURE_YEAR: string;
  FILTERS_NOMENCLATURE_SECTION: string;
  FILTERS_NOMENCLATURE_CASE: string;
  FILTERS_NOMENCLATURE_SIGNING: string;
  FILTERS_NOMENCLATURE_SUBSECTIONS: string;

  FILTERS_NSI_TK: string;
  FILTERS_NSI_STATUS: string;
}

export const fields: ITkGenerationFields = {
  OIK: "oikCode",
  FILE_TYPE: "fileType",
  ADD_MRD: "addMRD",
  IS_MRD_EXPIRED: "mrdExpired",
  IS_HYBRID_SAME_GUID: "addHybridCaseGuidSameAsPaperCase",
  ADD_CASE: "addHybridInventoryAndPaperCase",
  ADD_PAPER_CASE: "addPaperCase",
  WITH_HISTORICAL_NOTE: "withHistoricalNote",
  MIN_SIZE: "minMb",
  MAX_SIZE: "maxMb",
  SIGNING: "signing",
  IS_CUSTOM_FILE: "isCustomFile",
  INVENTORY_FILTER: "inventoryProjId",
  INVENTORY_NUMBER: "invCount",
  EAD_NUMBER: "eadCount",
  APPLICATIONS_NUMBER: "attachmentCount",
  OIK_GENERATE_TK: "tkGuid",
  OIK_GENERATE_CODE_RESPONSE: "archiveListResponseCode",
  OIK_GENERATE_TEXT: "message",
  VERSION: "projectVersion",
  ID: "id",
  MAIN_FILE: "mainFile",
  ADDITIONAL_FILES: "additionalFiles",
  TRUSTED_CERT: "trustedCertId",
  REPRESENTATIVE_CERT: "representativeCertId",
  PREFACE_REQUIRED: "prefaceRequired",
  INVENTORY_CATEGORY: "inventoryCategory",

  FILTERS_ERC_PROTOCOLS_OIK: "ercoikCode",
  FILTERS_ERC_SIGNING: "ercsigning",
  FILTERS_ERC_PROTOCOLS_FILE_TYPE: "ercfileType",
  FILTERS_ERC_PROTOCOLS_MIN_SIZE: "ercminSize",
  FILTERS_ERC_PROTOCOLS_MAX_SIZE: "ercmaxSize",
  FILTERS_ERC_PROTOCOLS_APPLICATIONS_NUMBER: "ercattachmentCount",
  FILTERS_ERC_PROTOCOLS_INVENTORY_NUMBER: "ercinvCount",
  FILTERS_ERC_PROTOCOLS_EAD_NUMBER: "erceadCount",
  FILTERS_ERC_PROTOCOLS_ID: "ercId",
  FILTERS_ERC_PROTOCOLS_VERSION: "ercVersion",
  FILTERS_ERC_ADD_CASE: "ercAddCase",
  FILTERS_ERC_ADD_PAPER_CASE: "ercAddPaperCase",
  FILTERS_ERC_WITH_HISTORICAL_NOTE: "ercWithHistoricalNote",

  FILTERS_NOMENCLATURE_OIK: "nomoikCode",
  FILTERS_NOMENCLATURE_YEAR: "nomyear",
  FILTERS_NOMENCLATURE_SECTION: "nomsectionCount",
  FILTERS_NOMENCLATURE_CASE: "nomcaseCount",
  FILTERS_NOMENCLATURE_SIGNING: "nomsigning",
  FILTERS_NOMENCLATURE_SUBSECTIONS: "subsection",

  FILTERS_NSI_TK: "nsiTkGuid",
  FILTERS_NSI_STATUS: "accepted",
};
