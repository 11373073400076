

































































































































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import { currentFilters as setCurrentFilters } from "../utils/defaultCurrentFilters";
import SelectFilter from "@monorepo/uikit/src/components/common/Select/SelectFilter.vue";
import TextFilter from "@monorepo/uikit/src/components/tableViews/TextFilter.vue";
import CheckboxElement from "@monorepo/uikit/src/components/Settings/CheckboxElement.vue";
import DatePicker from "@monorepo/uikit/src/components/tableViews/DatePicker.vue";
import { mapActions, mapGetters } from "vuex";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/tkGeneration/viewTitle";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/tkGeneration/filtersElements";
import { fields } from "@monorepo/utils/src/variables/projectsData/tkGeneration/fields";
import { convertToSelectItems } from "@monorepo/utils/src/utils/convertToSelectItems";
import eventBus from "@monorepo/utils/src/eventBus";
import useFilter from "@monorepo/utils/src/composables/useFilter";
import SelectCard from "@monorepo/uikit/src/components/common/Select/SelectCard.vue";

export default defineComponent({
  components: {
    TextFilter,
    DatePicker,
    SelectFilter,
    CheckboxElement,
    SelectCard,
  },
  data() {
    return {
      filters: setCurrentFilters(),
      filtersElements,
      viewUniqKey,
      fields,
    };
  },
  computed: {
    ...mapGetters("tkGeneration", ["fieldFilters", "fileTypes", "oiks", "isTableLoading", "certificates", "inventoryCategories"]),
    fileTypesSelectItems(): { title: string; value: string }[] {
      return convertToSelectItems(this.fileTypes);
    },
    oiksSelectItems(): { title: string; value: string }[] {
      return convertToSelectItems(this.oiks, "shortName", "code");
    },
    inventoryCategorySelectItems(): { title: string; value: string }[] {
      return this.inventoryCategories;
    },
    certSelectItems(): any[] {
      return (this.certificates || []).map((item: any) => ({
        value: item.value,
        title: item.displayName || item.value,
      }));
    },
  },
  methods: {
    ...mapActions("tkGeneration", ["clearFilters", "addFilter", "getCertificates"]),
    resetDisabledFields(value: boolean) {
      this.addFilterInputValue(fields.IS_CUSTOM_FILE, value);
      if (value) {
        this.filters[fields.FILE_TYPE] = "";
        this.filters[fields.MIN_SIZE] = "";
        this.filters[fields.MAX_SIZE] = "";
        this.filters[fields.APPLICATIONS_NUMBER] = "";
        this.addFilter(this.filters);
      }
    },
    resetInventoryKind(key: string, value: boolean) {
      this.addFilterInputValue(key, value);
      if (!value) {
        return;
      }
      const isPaperOnly = key === fields.ADD_PAPER_CASE;
      const inventoryKindKey = !isPaperOnly ? fields.ADD_PAPER_CASE : fields.ADD_CASE;
      this.filters[inventoryKindKey] = false;
      if (isPaperOnly) {
        this.filters[fields.FILE_TYPE] = "";
        this.filters[fields.MIN_SIZE] = "";
        this.filters[fields.MAX_SIZE] = "";
        this.filters[fields.APPLICATIONS_NUMBER] = "";
        this.filters[fields.EAD_NUMBER] = "";
      }
      this.addFilter(this.filters);
    },
    resendCerts() {
      this.getCertificates();
    },
    setCurrentFilters,
    addFilterInputValue(key: string, value: string | boolean) {
      this.filters[key] = value;
      this.addFilter(this.filters);
    },
    clearAllFilters({ id }: { id: number }) {
      if (id === 26) {
        this.filters = setCurrentFilters();
        this.addFilter(this.filters);
      }
    },
  },
  mounted() {
    this.addFilter(this.filters);
  },
  beforeMount() {
    eventBus.$on("newNotification", this.clearAllFilters);
  },
  beforeDestroy() {
    eventBus.$off("newNotification", this.clearAllFilters);
  },
  setup(props, context) {
    const currentFilters = ref({});
    const notHiddenKeys = ref([] as string[]);
    const isShowToggle = ref(false);
    const { filter } = useFilter(
      context,
      { value: true },
      "tkGeneration",
      {},
      currentFilters,
      () => ({}),
      filtersElements as { value: string; title: string }[],
      notHiddenKeys,
      isShowToggle
    );

    return {
      filter,
    };
  },
});
